<template>
	<div class="header_back">
		<div class="header_container">
			<div class="header_logo">
				<div class="toptile">三元雀庄管理系统</div>
			</div>
			<el-menu :default-active="activeIndex" class="el-menu-demo elmenuback" mode="horizontal" @select="handleSelect">
				<el-menu-item v-for="item, index in options" :key="index" 
					:index="index + ''" class="elmenutext">
					{{ item.name }}
				</el-menu-item>
				<!-- <el-menu-item index="2" class="elmenutext" @click="$router.push('match')">房间</el-menu-item>
				<el-menu-item index="3" class="elmenutext" @click="$router.push('game')">单局</el-menu-item>
				<el-menu-item index="4" class="elmenutext" @click="$router.push('user')">用户</el-menu-item>
				<el-menu-item index="5" class="elmenutext" @click="$router.push('order')">账单</el-menu-item>
				<el-menu-item index="6" class="elmenutext" @click="$router.push('rank')">排名</el-menu-item>
				<el-menu-item index="7" class="elmenutext" @click="$router.push('upload')">其它</el-menu-item> -->
			</el-menu>
			<el-select v-model="activeIndex" placeholder="请选择" class="eloptionback" @change="selectChange">
				<el-option
					v-for="item, index in options" :key="index"
					:label="item.name"
					:value="index + ''">
				</el-option>
			</el-select>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				options: [{
          name: '首页',
          router: '/home'
        },{
          name: '房间',
          router: '/match'
        },{
          name: '单局',
          router: '/game'
        },{
          name: '用户',
          router: '/user'
        },{
          name: '账单',
          router: '/order'
        },{
          name: '排名',
          router: '/rank'
        },{
          name: '其它',
          router: '/other'
        }],
        value: ''
			}
		},
		props: {
			activeIndex: {
				type: String,
				default: '0'
			}
		},
		methods: {
      handleSelect(key) {
				this.activeIndex = key + '';
				this.$router.push(this.options[key].router);
      },
			selectChange(key){
				this.activeIndex = key + '';
				this.$router.push(this.options[key].router);
			}
    }
	}
</script>

<style scoped>
.header_back{
	height: 60px;
}
.header_container{
	display: flex;
	justify-content: space-between;
	margin: auto;
	align-items: center;
	max-width: 1126px;
}
.avator{
	width: 36px;
	height: 36px;
	border-radius: 50%;
	margin-right: 37px;
}
.el-dropdown-menu__item{
	text-align: center;
}
.toptile{
	display: inline-block;
	height: 40px;
	margin-top: 18px;
	margin-left: 20px;
}
.elmenutext{
	font-size: 18px;
}
.eloptionback{
	margin-right: 12px;
}
@media (max-width: 680px) {
  .elmenuback {
    display: none;
  }
}
@media (min-width: 681px) {
  .eloptionback {
    display: none;
  }
}
</style>
