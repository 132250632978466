<template>
  <div>
    <footer class="footer">
      <div class="container">
        <div class="content has-text-centered">
          <p>
            <small>
              三元雀庄 &copy; 2022<br>
              All Rights Reserved.
            </small>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
  export default {
    name: 'Footer',
  }
</script>
<style scoped>
.footer{
  padding: 1.5rem 1.5rem 3rem;
  text-align: center;
  background: #000;
  color: #fff;
}
</style>
